import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APInput from 'apex-web/lib/components/common/APInput';
import APButton from 'apex-web/lib/components/common/APButton';
import APIcon from 'apex-web/lib/components/common/APIcon';
import { required } from '../../helpers/formValidations';
import APPasswordInput from 'apex-web/lib/components/common/APPasswordInput/APPasswordInput';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';

import { defaultPath } from 'apex-web/lib/routeTemplates';
import path from '../../helpers/path';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import TradingServerComponent from 'apex-web/lib/components/TradingServer/TradingServerComponent';
import { styleNames } from '../../propTypes/commonComponent';
import '../../pages/css/Style.css';
import '../../pages/css/bootstrap.min.css';

// import '../../styles/components/common/StandaloneForm.css';
// import './LoginFormComponent.css';

var _slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;
    try {
      for (
        var _i = arr[Symbol.iterator](), _s;
        !(_n = (_s = _i.next()).done);
        _n = true
      ) {
        _arr.push(_s.value);
        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }
    return _arr;
  }
  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError(
        'Invalid attempt to destructure non-iterable instance'
      );
    }
  };
})();

var baseClasses = getBEMClasses('standalone-form');
var loginFormClasses = getBEMClasses('login-form');

export var LoginFormComponent = function LoginFormComponent(props, context) {
  var handleSubmit = props.handleSubmit,
    errorMsg = props.errorMsg,
    submitting = props.submitting,
    doSubmit = props.doSubmit,
    isConnected = props.isConnected,
    useEmailAsUsername = props.useEmailAsUsername,
    active = props.active,
    siteName = props.siteName,
    gateway = props.gateway,
    formGateway = props.formGateway,
    resetForm = props.resetForm;

  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    customGateway = _useState2[0],
    setCustomGateway = _useState2[1];

  if (formGateway === 'CUSTOM' && !customGateway) {
    setCustomGateway(true);
    resetForm('gateway');
  }

  return React.createElement(
    'div',
    {
      className: 'login__screen__main gredientbg_one'
    },
    React.createElement(
      'div',
      {
        className: 'mobile__header'
      },
      React.createElement(
        'span',
        { className: '' }
        // context.t("Log In to {n}", {
        //   n: siteName,
        // })
      ),
      React.createElement('span', {
        className: ''
      })
    ),
    React.createElement(
      'header',
      {},
      React.createElement(
        'div',
        {
          className: 'login__screen__btns'
        },
        React.createElement(
          'a',
          {
            className: 'theme-btn'
            // href="#"
          },
          'Log In'
        ),
        React.createElement(
          Link,
          {
            className: 'theme-btn withoutgradient me-0',
            to: path('/signup'),
            // className: "",
            disabled: true

            // href="#"
          },
          'Sign Up'
        )
      )
    ),

    React.createElement(
      'section',
      {
        className: 'login__form__center'
      },
      React.createElement(
        'div',
        {
          className: 'login__screen__content'
        },
        React.createElement(
          'div',
          {
            className: 'container'
          },
          React.createElement(
            'div',
            {
              className: 'row align-items-center'
            },
            React.createElement(
              'div',
              {
                className: 'col-lg-6'
              },
              React.createElement('h1', {}, 'Sign in to CryptoSX')
              // React.createElement(
              //   'p',
              //   { style: { color: 'red', marginTop: '10px' } },
              //   'Important Notice : '
              // ),
              // React.createElement(
              //   'p',
              //   { style: { color: 'red', lineHeight: '1.3' } },
              //   'There will be a system infrastructure Upgrade.'
              // ),
              // React.createElement(
              //   'p',
              //   { style: { color: 'red' } },
              //   'Our services will be temporarily unavailable.'
              // ),
              // React.createElement(
              //   'p',
              //   { style: { color: 'red' } },
              //   'For US Time, it would be'
              // ),
              // React.createElement(
              //   'p',
              //   { style: { color: 'red', fontFamily: 'Now-Bold' } },
              //   'Feb 3rd, 12:00 pm - Feb 4th, 12:00 am (UTC).'
              // ),
              // React.createElement(
              //   'p',
              //   { style: { color: 'red' } },
              //   'For Hong Kong Time, it would be'
              // ),
              // React.createElement(
              //   'p',
              //   { style: { color: 'red', fontFamily: 'Now-Bold' } },
              //   'Feb 3rd, 8:00 pm - Feb 4th, 8:00 am (HKT)'
              // ),
              // React.createElement(
              //   'p',
              //   { style: { color: 'red' } },
              //   'The process will take around 12 hours.'
              // )
            ),
            React.createElement(
              'div',
              {
                className: 'col-lg-6'
              },
              React.createElement(
                'div',
                {
                  className: 'login__form__main'
                },
                React.createElement(
                  'form',
                  {
                    onSubmit: handleSubmit(function(values) {
                      return doSubmit(values, isConnected);
                    }),
                    className: ''
                  },
                  errorMsg &&
                    React.createElement('p', { className: '' }, errorMsg),
                  React.createElement(APInput, {
                    type: 'text',
                    name: 'username',
                    customClass: '',
                    placeholder: 'Username',
                    // className:'form-group',
                    label: useEmailAsUsername
                      ? context.t('Email')
                      : context.t('Username'),
                    validate: [required]
                  }),

                  React.createElement(APPasswordInput, {
                    type: 'password',
                    name: 'password',
                    customClass: '',
                    placeholder: 'Password',

                    label: context.t('Password'),
                    validate: [required]
                  }),
                  (!gateway || customGateway) &&
                    React.createElement(APInput, {
                      type: 'text',
                      name: 'gateway',
                      customClass: '',
                      label: context.t('Gateway'),
                      validate: [required]
                    }),
                  !customGateway &&
                    Array.isArray(gateway) &&
                    React.createElement(TradingServerComponent, null),
                  // React.createElement("hr", { className: "" }),
                  React.createElement(
                    APButton,
                    {
                      type: 'submit',
                      disabled: submitting,
                      className: 'theme-btn',
                      customClass: '',
                      styleName: styleNames.additive
                    },
                    submitting
                      ? context.t('Processing...')
                      : context.t('Log In')
                  )
                ),
                React.createElement(
                  'div',
                  { className: 'cant__login' },
                  active &&
                    React.createElement(
                      'ul',
                      { className: '' },
                      React.createElement(
                        'li',
                        { className: '' },
                        React.createElement(
                          Link,
                          {
                            to: path('/signup'),
                            className: '',
                            disabled: true
                          },
                          context.t('Sign Up')
                        )
                      ),
                      React.createElement(
                        'li',
                        { className: '' },
                        React.createElement(
                          Link,
                          {
                            to: path('/problem-logging-in'),
                            className: '',
                            disabled: true
                          },
                          context.t("Can't Login?")
                        )
                      )
                    )
                ),
                React.createElement(
                  'div',
                  { className: 'cant__login cant_login_sto' },
                  active &&
                    React.createElement(
                      'a',
                      {
                        href:
                          'https://investorportal.cryptosx.io/settings/verification',
                        target: '_blank'
                        // className: "cant__login",
                        // style: 'color:white',
                        // disabled: true
                      },
                      context.t('For STO Investors: Start Accreditation')
                    )
                )
              )
            )
          )
        )
      )
    )
  );
};

LoginFormComponent.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

LoginFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string
};

LoginFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(LoginFormComponent, defaultPath.path);
