import React, { useEffect } from 'react';
import L from 'lodash';
import './InstrumentTableComponent.css';

const InstrumentTickers = props => {
  return (
    <div className="instrument-table">
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">
          {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
        </span>
        <span className="instrument-table__value instrument-table__value--last-price">
          {/* this price is changed, requested by Philip.
          For price come to 0 fall-back.
          If the price is 0 , use another from props. 
          Feel free to add more if-else case (LMAO)
          */}
          {L.get(props.level1, ['LastTradedPx']) == 0
            ? L.get(props.level1, ['SessionClose']) == 0
              ? L.get(props.level1, ['BestOffer'])
              : L.get(props.level1, ['LastTradedPx'])
            : L.get(props.level1, ['SessionClose'])}
        </span>
      </div>
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">Your Balance:</span>
        <span className="instrument-table__value">
          <span className="instrument-table__title">
            {L.get(props.positions, ['position1', 'ProductSymbol'])}{' '}
          </span>
          {L.get(props.positions, ['position1', 'Amount'])}{' '}
          <span className="instrument-table__title">
            {' '}
            / {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
          </span>
          {/* change the number to a tofixed(2) , if only "-" , fallback to default  */}
          {L.get(props.positions, ['position2', 'Amount']) != '-'
            ? Number.parseFloat(
                L.get(props.positions, ['position2', 'Amount'])
              ).toFixed(2)
            : L.get(props.positions, ['position2', 'Amount'])}
        </span>
      </div>
      <div className="instrument-table__row instrument-table__row--last-price">
        <span className="instrument-table__title">Avaliability:</span>
        <span className="instrument-table__value">
          <span className="instrument-table__title">
            {L.get(props.positions, ['position1', 'ProductSymbol'])}{' '}
          </span>
          {L.get(props.positions, ['position1', 'AvailableBalance'])}{' '}
          <span className="instrument-table__title">
            {' '}
            / {L.get(props.positions, ['position2', 'ProductSymbol'])}{' '}
          </span>
          {/* change the number to a tofixed(2) , if only "-" , fallback to default  */}
          {L.get(props.positions, ['position2', 'AvailableBalance']) != '-'
            ? Number.parseFloat(
                L.get(props.positions, ['position2', 'AvailableBalance'])
              ).toFixed(2)
            : L.get(props.positions, ['position2', 'AvailableBalance'])}
        </span>
      </div>
    </div>
  );
};
export default InstrumentTickers;
