import React from 'react';
import Loader from '../common/Loader'

let mobilumHost = 'https://markets.cryptosx.io/credit-card';
//let mobilumHost = 'https://card.cryptosx.io';
let mobilumApiKey = 'myapikey';

let Mobilum = props =>
(props => {
  const [widget, setWidget] = React.useState({
    widgetHTML: undefined,
    scriptURL: undefined
  });
  let [isFetching, stopFetching] = (function (state) {
    return [state[0], ((f, g = () => f(false)) => g)(state[1])];
  })(React.useState(true));
  React.useEffect(() => {
    fetch(props.url, {
      method: 'POST',
      headers: {
        authorization: mobilumApiKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: props.email,
      })
    })
      .then(resp => resp.json())
      .then(_ => (stopFetching(),_))
      .then(
        resp =>
          !resp['success']
            ? Promise.reject(new Error(resp['errors'][0]['message']))
            : Promise.resolve()
                .then(() => {
                  setWidget({
                    widgetHTML: atob(resp['result']['widgetBase64Html']),
                    scriptURL: atob(resp['result']['widgetBase64ScriptUrl'])
                  });
                })
                .then(() => ({
                  elemScript: document.createElement('script'),
                  elemMobilum: document.getElementById('mobilum-container')
                }))
                .then(
                  _0 => (
                    (_0.elemScript.async = true),
                    (_0.elemScript.src = widget.scriptURL),
                    _0.elemMobilum.appendChild(_0.elemScript),
                    _0
                  )
                )
      )
      .catch(alert);
  }, []);
  if (isFetching)
    return <Loader style={{margin: 'auto'}} />;
  return (
    <div id="mobilum-container">
      {/**<div dangerouslySetInnerHTML={{ __html: widget.widgetHTML }} /> */}
      {/*widget.widgetHTML && (
        <iframe
          src={'data:text/html,' + widget.widgetHTML}
          width="100%"
          height="500px"
        />
      )*/}
      {widget.widgetHTML && (
        <iframe srcDoc={widget.widgetHTML} width="100%" height="500px" />
      )}
    </div>
  );
})({
  email: props.email,
  url: (query => `${mobilumHost}/mobilum/widgetWithOrderAmount?${query}`)(
    (g => {
      let p = Object.keys(props).reduce((acc, k) => k === 'email' ? acc : Object.assign(acc, {[k]: props[k]}), {});
      return Object.keys(p).reduce(
        (f, k) =>
          (k => (acc, ...es) => g([`${k}=${p[k]}`], ...f(acc, ...es)))(k),
        g
      )([]);
    })(
      (acc, ...es) => (es.length == 0 ? acc : (acc.push(...es), acc))
    ).join('&')
  )
});
export default Mobilum;

