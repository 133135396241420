import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import APInput from 'apex-web/lib/components/common/APInput';
import APPasswordInput from 'apex-web/lib/components/common/APPasswordInput/APPasswordInput';
import APButton from 'apex-web/lib/components/common/APButton';
import {
  required,
  email,
  matchingPassword,
  validatePassword
} from 'apex-web/lib/helpers/formValidations';
import { renderFormInputs } from 'apex-web/lib/helpers/formGeneratorHelper';
import path from 'apex-web/lib/helpers/path';
import passwordScore from 'apex-web/lib/helpers/passwordScore';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import { defaultPath } from 'apex-web/lib/routeTemplates';
import { styleNames } from 'apex-web/lib/propTypes/commonComponent';

// import 'apex-web/lib/styles/components/common/StandaloneForm.css';
// import './SimpleSignupFormComponent.css';

var _createClass = (function() {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function(Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
})();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError('Cannot call a class as a function');
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError(
      "this hasn't been initialised - super() hasn't been called"
    );
  }
  return call && (typeof call === 'object' || typeof call === 'function')
    ? call
    : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) {
    throw new TypeError(
      'Super expression must either be null or a function, not ' +
        typeof superClass
    );
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass)
    Object.setPrototypeOf
      ? Object.setPrototypeOf(subClass, superClass)
      : (subClass.__proto__ = superClass);
}

var baseClasses = getBEMClasses('standalone-form');
var signupFormClasses = getBEMClasses('signup-form');

var SimpleSignupFormComponent = (function(_Component) {
  _inherits(SimpleSignupFormComponent, _Component);

  function SimpleSignupFormComponent() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SimpleSignupFormComponent);

    for (
      var _len = arguments.length, args = Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }

    return (
      (_ret = ((_temp = ((_this = _possibleConstructorReturn(
        this,
        (_ref =
          SimpleSignupFormComponent.__proto__ ||
          Object.getPrototypeOf(SimpleSignupFormComponent)).call.apply(
          _ref,
          [this].concat(args)
        )
      )),
      _this)),
      (_this.getPasswordStrength = function() {
        var _this2 = _this,
          context = _this2.context;

        var score = passwordScore(_this.props.passwordValue);
        var passwordStrengthValues = {
          0: context.t('Very Weak'),
          1: context.t('Weak'),
          2: context.t('Medium'),
          3: context.t('Strong'),
          4: context.t('Very Strong')
        };

        return passwordStrengthValues[score];
      }),
      (_this.handleChange = function() {
        if (_this.props.errorMsg) {
          _this.props.clearSignupError();
        }
      }),
      _temp)),
      _possibleConstructorReturn(_this, _ret)
    );
  }

  //   <div class="login__screen__main gredientbg_one">
  //   <aside>
  //     <div id="mySidebar" class="sidebar__main" >
  //       <div class="mainlinks__sidebar">
  //         <div class="brand__logo">
  //           <a href="#" class="favicon"><img src="img/fav.svg" alt="fav"></a>
  //           <a href="#" class="fullicon"><img src="img/fulllogo.svg" alt="fav"></a>
  //         </div>
  //         <a href="#" class="active"><img src="img/home.svg" alt="home"><span class="icon-text">Home </span></a>
  //         <a href="#"><img src="img/dollar.svg" alt="home"><span class="icon-text"></span>Cryptocurrencies</a></span>
  //         </a>
  //         <a href="#"><img src="img/presentation.svg" alt="home"><span class="icon-text"></span>Security Tokens</span></a>
  //         <a href="#"><img src="img/assets-token.svg" alt="home"><span class="icon-text"></span>Asset
  //           Tokenisation<span></a>
  //       </div>
  //       <div class="helplinks__sidebar">
  //         <a href="#"><i class="bi bi-question-circle"></i><span class="icon-text">Help </span></a>
  //         <a href="#"><i class="bi bi-translate"></i><span class="icon-text"></span>Language</a></span>
  //         </a>
  //       </div>
  //     </div>
  //   </aside>
  //   <div class="header__mobile__action d-xl-none">
  //     <div class="mobile__header ">
  //       <span class="rightsidebar btn btn-text"><i class="bi bi-filter-left"></i></span>
  //       <span class="leftsidebar btn btn-text"><i class="bi bi-filter-right"></i></span>
  //   </div>
  //   </div>
  //  <header>
  //     <div class="login__screen__btns">
  //         <a href="#" class="theme-btn">Sign In</a>
  //         <a href="#" class="theme-btn withoutgradient me-0">Sign Up</a>
  //     </div>
  //  </header>
  //  <section class="login__form__center">
  //    <div class="login__screen__content">
  //      <div class="container">
  //        <div class="row align-items-center">
  //          <div class="col-lg-6">
  //            <h1>Sign in to CryptosX</h1>
  //          </div>
  //          <div class="col-lg-6">
  //            <div class="login__form__main">
  //              <form action="#">
  //                <div class="form-group">
  //                  <input type="text" class="form-control" placeholder="Username" id="username">
  //                </div>
  //                <div class="form-group">
  //                 <input type="password" class="form-control" placeholder="Password" id="username">
  //               </div>
  //               <button type="submit" class="theme-btn">Sign In</button>
  //              </form>
  //              <div class="cant__login">
  //                <ul>
  //                  <li><a href="#">Sign Up</a></li>
  //                  <li><a href="#">Can't Log In</a></li>
  //                </ul>
  //              </div>
  //            </div>
  //          </div>
  //        </div>
  //      </div>
  //    </div>
  //  </section>
  // </div>

  //   <div class="header__mobile__action d-xl-none">
  //     <div class="mobile__header ">
  //       <span class="rightsidebar btn btn-text"><i class="bi bi-filter-left"></i></span>
  //       <span class="leftsidebar btn btn-text"><i class="bi bi-filter-right"></i></span>
  //   </div>
  //   </div>
  //  <header>
  //     <div class="login__screen__btns">
  //         <a href="#" class="theme-btn">Sign In</a>
  //         <a href="#" class="theme-btn withoutgradient me-0">Sign Up</a>
  //     </div>
  //  </header>
  //  <section class="login__form__center">
  //    <div class="login__screen__content">
  //      <div class="container">
  //        <div class="row align-items-center">
  //          <div class="col-lg-6">
  //            <h1>Sign in to CryptosX</h1>
  //          </div>
  //          <div class="col-lg-6">
  //            <div class="login__form__main">
  //              <form action="#">
  //                <div class="form-group">
  //                  <input type="text" class="form-control" placeholder="Username" id="username">
  //                </div>
  //                <div class="form-group">
  //                 <input type="password" class="form-control" placeholder="Password" id="username">
  //               </div>
  //               <button type="submit" class="theme-btn">Sign In</button>
  //              </form>
  //              <div class="cant__login">
  //                <ul>
  //                  <li><a href="#">Sign Up</a></li>
  //                  <li><a href="#">Can't Log In</a></li>
  //                </ul>
  //              </div>
  //            </div>
  //          </div>
  //        </div>
  //      </div>
  //    </div>
  //  </section>
  // </div>

  _createClass(SimpleSignupFormComponent, [
    {
      key: 'render',
      value: function render() {
        var context = this.context;
        var _props = this.props,
          handleSubmit = _props.handleSubmit,
          errorMsg = _props.errorMsg,
          submitting = _props.submitting,
          pristine = _props.pristine,
          passwordValue = _props.passwordValue,
          invalid = _props.invalid,
          done = _props.done,
          useEmailAsUsername = _props.useEmailAsUsername,
          additionalFields = _props.additionalFields;

        if (done) {
          return React.createElement(
            'p',
            { className: '' + baseClasses('success') },
            context.t(
              'Thanks for signing up, please check your inbox for a confirmation email'
            )
          );
        } else {
          //     <form action="#">
          //                <div class="form-group">
          //                  <input type="text" class="form-control" placeholder="Username" id="username">
          //                </div>
          //                <div class="form-group">
          //                 <input type="password" class="form-control" placeholder="Password" id="username">
          //               </div>
          //               <button type="submit" class="theme-btn">Sign In</button>
          //              </form>
          return React.createElement(
            'form',
            {
              onSubmit: handleSubmit,
              className: ''
            },
            // React.createElement(
            //   'div',
            //   { className: signupFormClasses('wrapper') },
            //   ),
            React.createElement(
              'div',
              { className: signupFormClasses('container') },
              !useEmailAsUsername &&
                React.createElement(
                  'div',
                  { className: 'form-group' },
                  React.createElement(APInput, {
                    type: 'text',
                    name: 'username',
                    className: 'form-control',
                    placeholder: 'username',
                    // label: context.t("Username"),
                    validate: [required, noWhiteSpace],
                    // customClass: signupFormClasses(),
                    onChange: this.handleChange
                  })
                ),
              React.createElement(APInput, {
                type: 'text',
                name: 'email',
                placeholder: 'Valid Email Address',
                className: 'form-control',
                // label: context.t("Email"),
                validate: [required, email],
                // customClass: signupFormClasses(),
                onChange: this.handleChange
              }),
              React.createElement(
                'div',
                { className: 'form-group' },
                React.createElement(APPasswordInput, {
                  type: 'password',
                  name: 'password',
                  placeholder: 'Password',
                  className: 'form-control',
                  // label: context.t("Password"),
                  validate: [required],
                  // info: passwordValue && this.getPasswordStrength(),
                  // customClass: signupFormClasses(),
                  onChange: this.handleChange
                })
                // React.createElement("p", { className: "form-text" } ,'Contains at least 8 characters, 1 number, 1 capital letter'),
              ),
              React.createElement(
                'div',
                { className: 'form-group' },
                React.createElement(APPasswordInput, {
                  type: 'password',
                  name: 'matchingPassword',
                  placeholder: 'Retype Password',

                  // label: context.t("Retype Password"),
                  // classes: "signup",
                  validate: [required, matchingPassword, validatePassword],
                  customClass: signupFormClasses(),
                  onChange: this.handleChange
                })
              ),
              renderFormInputs(additionalFields, signupFormClasses(), context),
              errorMsg &&
                React.createElement(
                  'p',
                  {
                    className:
                      baseClasses('error') + ' ' + signupFormClasses('error')
                  },
                  errorMsg
                )
            ),
            React.createElement(
              APButton,
              {
                type: 'submit',
                disabled: pristine || submitting || invalid,
                className: 'theme-btn',
                customClass: baseClasses() + ' ' + signupFormClasses(),
                styleName: styleNames.additive
              },
              submitting ? context.t('Processing...') : context.t('Sign Up')
            )
          );
        }
      }
    }
  ]);

  return SimpleSignupFormComponent;
})(Component);

SimpleSignupFormComponent.defaultProps = {
  handleSubmit: function handleSubmit() {},
  submitting: false
};

SimpleSignupFormComponent.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  referrer: PropTypes.string,
  search: PropTypes.string,
  shouldRedirect: PropTypes.bool
};

SimpleSignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SimpleSignupFormComponent;
