import React from 'react';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import 'apex-web/lib/layouts/Responsive/EotcBuySellLayout/EotcBuySellDesktopLayout.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import CardImg from '../images/custom/cards.png';
import ErrorImg from '../images/custom/error.webp';
import './JoomioPage.css';

const classes = getBEMClasses('eotc-buy-sell-layout');

const ErrorPage = () => {
  return (
    <React.Fragment>
      <div>
        <div className={classes('accent-bar')} />
        <div className={classes('container')}>
          <div className={classes('col', ['lg'])}>
            <div className="title-container">
              <div className="col-left">
                <h2>Card Purchase - Error</h2>
              </div>
              <div className="col-right">
                <img className="card-img" src={CardImg} />
              </div>
            </div>
            <div className="cp-container-dark">
              <img className="cp-success-img" src={ErrorImg} />
              <h1 className="cp-success-header">Order was unsuccessful!</h1>
              <h3 className="cp-success-text">
                We have encountered a problem with your credit card purchase,
                please try again or contact support at support@cryptosx.io.
              </h3>
            </div>
          </div>
        </div>
        <div className={classes('footer')}>
          <PageFooterComponent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withAuthentication(ErrorPage);
