import React from 'react';
import resize from 'apex-web/lib/hocs/resize';
import { connect } from 'react-redux';
// import InstrumentRowComponent from 'apex-web/lib/components/InstrumentRow/InstrumentRowComponent';
import InstrumentRowComponent from '../../components/InstrumentRow/InstrumentRowComponent';
import ConfirmLimitOrder from 'apex-web/lib/components/ConfirmLimitOrderModal/ConfirmLimitOrderModalContainer';
import OrderHistoryContainer from 'apex-web/lib/components/OrderHistoryComponents';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
// import 'apex-web/lib/layouts/TradingLayout/TradingLayout.css';
import './TradingLayout.css';
import ExchangePageSize from './ExchangePageSize';
import ExchangeCharts from './ExchangeCharts';
import Navbar from '../../components/NewNav';
import config from '../../config';
import { selectedInstrumentSelector } from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import instrumentPositionSelectors from 'apex-web/lib/redux/selectors/instrumentPositionSelectors';
import L from 'lodash';
import { objectExpression } from '@babel/types';
import { formatToThousands } from 'apex-web/lib/helpers/numberFormatter';

const tradingLayoutClasses = getBEMClasses('trading-layout');

class ExchangePage extends React.Component {
  componentDidMount() {
    // document.querySelector('.popover').classList.remove('popover');

    var coin_volumes = {
      btcusdt: 0,
      ethusd: 0,
      ethusdt: 0,
      usdtusd: 0,
      xrpusd: 0,
      ltcusd: 0,
      bchusd: 0,
      btceur: 0,
      etheur: 0,
      xrpeur: 0,
      ltceur: 0,
      bcheur: 0,
      ethbtc: 0,
      xrpbtc: 0,
      ltcbtc: 0,
      bchbtc: 0
    };

    for (const [key, value] of Object.entries(coin_volumes)) {
      fetch(`https://www.bitstamp.net/api/v2/ticker/${key}`, {
        mode: 'cors',
        credentials: 'same-origin'
      })
        .then(response => response.json())
        .then(data => {
          coin_volumes[key] = formatToThousands(data.volume);
        });
    }

    setTimeout(() => {
      setInterval(() => {
        if (
          document.querySelector('.instrument-selector-popup__body') != null
        ) {
          let btcusdt_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[0].children[3];

          let ethusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[1].children[3];

          let ethusdt_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[2].children[3];

          let usdtusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[3].children[3];

          let xrpusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[4].children[3];

          let ltcusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[5].children[3];

          let bchusd_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[6].children[3];

          let btceur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[7].children[3];

          let etheur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[8].children[3];

          let xrpeur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[9].children[3];

          let ltceur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[10].children[3];

          let bcheur_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[11].children[3];

          let ethbtc_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[12].children[3];

          let xrpbtc_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[13].children[3];

          let ltcbtc_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[14].children[3];

          let bchbtc_vol = document.querySelector(
            '.instrument-selector-popup__body'
          ).children[2].children[15].children[3];

          btcusdt_vol.innerHTML = coin_volumes['btcusdt'];
          ethusd_vol.innerHTML = coin_volumes['ethusd'];
          ethusdt_vol.innerHTML = coin_volumes['ethusdt'];
          usdtusd_vol.innerHTML = coin_volumes['usdtusd'];
          xrpusd_vol.innerHTML = coin_volumes['xrpusd'];
          ltcusd_vol.innerHTML = coin_volumes['ltcusd'];
          bchusd_vol.innerHTML = coin_volumes['bchusd'];
          btceur_vol.innerHTML = coin_volumes['btceur'];
          etheur_vol.innerHTML = coin_volumes['etheur'];
          xrpeur_vol.innerHTML = coin_volumes['xrpeur'];
          ltceur_vol.innerHTML = coin_volumes['ltceur'];
          bcheur_vol.innerHTML = coin_volumes['bcheur'];
          ethbtc_vol.innerHTML = coin_volumes['ethbtc'];
          xrpbtc_vol.innerHTML = coin_volumes['xrpbtc'];
          ltcbtc_vol.innerHTML = coin_volumes['ltcbtc'];
          bchbtc_vol.innerHTML = coin_volumes['bchbtc'];
        }
      }, 500);
    }, 4000);
  }

  render() {
    const {
      OrderHistoryData: { usePagination },
      TradingLayout: { maxLines }
    } = config;

    const orderHistoryConfig = {
      usePagination,
      maxLines,
      filterMode: 'selectedInstrument'
    };

    return (
      <React.Fragment>
        {/* <Navbar /> */}
        <div className={tradingLayoutClasses('container')}>
          <InstrumentRowComponent
            level1={this.props.getLevel1(
              this.props.selectedInstrument['InstrumentId']
            )}
            positions={this.props.positions}
          />
          <div className={tradingLayoutClasses('body')}>
            <div className={tradingLayoutClasses('chart-container')}>
              <ExchangeCharts
                width={this.props.width}
                symbol={this.props.formatSymbol(
                  this.props.selectedInstrument.Symbol
                )}
              />
            </div>
            <OrderHistoryContainer
              filterMode="selectedInstrument"
              config={orderHistoryConfig}
            />
            <ExchangePageSize height={this.props.height} />
          </div>
          <ConfirmLimitOrder />
        </div>
      </React.Fragment>
    );
  }
}

let pipe = (...fns) => arg => fns.reduce((b, a) => a(b), arg);
let mapStateToProps = state => ({
  positions: instrumentPositionSelectors(state),
  getLevel1: instrumentID => L.get(state.apexCore, ['level1', instrumentID]),
  selectedInstrument: selectedInstrumentSelector(state),
  formatSymbol: symbol => {
    if (symbol === 'USDTUSD') {
      return `KRAKEN:${symbol}`;
    } else if (symbol === 'RVNUSDT') {
      return `BINANCE:${symbol}`;
    } else {
      return `BITSTAMP:${symbol}`;
    }
  }
});
export default pipe(
  connect(mapStateToProps),
  resize
)(ExchangePage);
