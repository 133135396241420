import React from 'react';
import PropTypes from 'prop-types';

// import PageFooterLayout from '../layouts/PageFooterLayout/PageFooterLayout';

import withAuthentication from 'apex-web/lib/hocs/withAuthentication';
import WalletCardGridContainer from 'apex-web/lib/components/WalletCards/WalletCardGridContainer';
import WalletRowListContainer from '../components/WalletRows/WalletRowListContainer';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import './WalletsPage.css';
import PageFooterComponent from 'apex-web/lib/components/PageFooter/PageFooterComponent';
import TransferRequestNotificationList from 'apex-web/lib/components/TransferRequestsNotification/';
import { getGateway } from 'apex-web/lib/helpers/wsHelper';
import { connect } from 'react-redux';
import {
  getUserConfig,
  getUserInfo
} from 'apex-web/lib/redux/actions/userActions';
//import * as ff from 'apex-web/lib/redux/selectors/';

const layoutClasses = getBEMClasses('wallets-page');

const detailsLink = '/wallets/product-details';

const WalletsPage = (props, context) => {
  React.useEffect(() => {
    props.getUserInfo();
    props.getUserConfig();
  }, []);

  return (
    <React.Fragment>
      <div className={layoutClasses('accent-bar')} />
      <div className={layoutClasses('container')}>
        <div className={layoutClasses('wallet-list-container-header')}>
          {context.t('Total Bonus Earned')}
        </div>
        <div className="interest-earned-container">
          <div className="interest-earned-message">
            <h4>
              {context.t(`To this date, you have earned:`)}{' '}
              <b className="interest-amount">{`$${props.getInterest()} USDT`}</b>
            </h4>
          </div>
        </div>
        <div className={layoutClasses('wallet-list-container-header')}>
          {context.t('Funded Accounts')}
        </div>
        <div className={layoutClasses('wallet-card-container')}>
          <WalletCardGridContainer detailsLink={detailsLink} />
        </div>
        <div className={layoutClasses('wallet-list-container-header')}>
          {context.t('Unfunded Accounts')}
        </div>
        <div className={layoutClasses('wallet-list-container')}>
          <WalletRowListContainer detailsLink={detailsLink} />
        </div>
      </div>
      <div className={layoutClasses('footer')}>
        <PageFooterComponent />
      </div>
    </React.Fragment>
  );
};

WalletsPage.contextTypes = {
  t: PropTypes.func.isRequired
};

function userInterestSelector(state) {
  if (state.user) {
    let userConfig = state.user.userConfig;
    userConfig = userConfig.filter(config => config['Key'] === 'interest');
    if (userConfig.length > 0) {
      return userConfig[0]['Value'];
    }
    return '0.00';
  }
  return '0.00';
}
var mapStateToProps = function mapStateToProps(state) {
  return {
    user: state.user,
    getInterest: () => userInterestSelector(state)
  };
};
var mapDispatchToProps = {
  getUserInfo,
  getUserConfig
};
function compose(...morphisms) {
  let morphism = o => o;
  for (let m in morphisms) {
    let _morphism = morphism;
    morphism = o => m(_morphism(o));
  }
  morphism = morphisms[0];
  return morphism;
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withAuthentication
)(WalletsPage);
