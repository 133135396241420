import React from 'react';
import PropTypes from 'prop-types';
import PriceChartContainer from 'apex-web/lib/components/PriceChart/PriceChartContainer';
import DepthChartContainer from 'apex-web/lib/components/DepthChart/DepthChartContainer';
import config from 'apex-web/lib/config';
import TradingLayoutCharts1280Px from '../../layouts/Responsive/TradingLayout/TradingLayoutCharts1280Px';
// import TradingLayoutCharts1280Px from 'apex-web/lib/layouts/Responsive/TrandingLayout/TradingLayoutCharts1280Px';
import { getBEMClasses } from '../../helpers/cssClassesHelper';
import TradingViewWidget, {
  Themes,
  RangeTypes
} from 'react-tradingview-widget';

const tradingLayoutClasses = getBEMClasses('trading-layout');

const renderCharts = instrument => {
  if (
    instrument === 'BITSTAMP:AGWDUSD' ||
    instrument === 'BITSTAMP:CCTUSDT' ||
    instrument === 'BITSTAMP:HCSUSDT' ||
    instrument === 'BITSTAMP:TIRCUSDT' ||
    instrument === 'BITSTAMP:LQGOUSDT' ||
    instrument === 'BITSTAMP:2UTUSDT' ||
    instrument === 'BITSTAMP:DIGauUSDT' ||
    instrument === 'BITSTAMP:MAMIUSDT' ||
    instrument === 'BITSTAMP:MSTOUSDT' ||
    instrument === 'BITSTAMP:DIGauBTC' ||
    instrument === 'BITSTAMP:DIGauETH' ||
    instrument === 'BITSTAMP:U2UUSDT' ||
    instrument === 'BITSTAMP:IPGSUSDT'
  ) {
    return <PriceChartContainer chart={'APEX'} />;
  } else {
    return (
      <TradingViewWidget
        symbol={instrument}
        //symbol={`BITSTAMP:BTCUSD`}
        locale="en"
        theme={Themes.DARK}
        range={RangeTypes.ALL}
        hide_legend={true}
        hide_side_toolbar={false}
        locale="en"
        container_id="trading-view-chart"
        autosize
      />
    );
  }
};

const renderChartsMobile = instrument => {
  if (
    instrument === 'BITSTAMP:AGWDUSD' ||
    instrument === 'BITSTAMP:CCTUSDT' ||
    instrument === 'BITSTAMP:HCSUSDT' ||
    instrument === 'BITSTAMP:TIRCUSDT' ||
    instrument === 'BITSTAMP:LQGOUSDT' ||
    instrument === 'BITSTAMP:2UTUSDT' ||
    instrument === 'BITSTAMP:DIGauUSDT' ||
    instrument === 'BITSTAMP:MAMIUSDT' ||
    instrument === 'BITSTAMP:MSTOUSDT' ||
    instrument === 'BITSTAMP:DIGauBTC' ||
    instrument === 'BITSTAMP:DIGauETH' ||
    instrument === 'BITSTAMP:U2UUSDT' ||
    instrument === 'BITSTAMP:IPGSUSDT'
  ) {
    return <PriceChartContainer chart={'APEX'} />;
  } else {
    return (
      <TradingViewWidget
        symbol={instrument}
        //symbol={`BITSTAMP:BTCUSD`}
        locale="en"
        theme={Themes.DARK}
        range={RangeTypes.ALL}
        hide_legend={true}
        hide_side_toolbar={true}
        locale="en"
        container_id="trading-view-chart"
        autosize
      />
    );
  }
};

class ExchangeCharts extends React.Component {
  renderCharts = () => {
    const props = {
      priceChart: PriceChartContainer,
      depthChart: DepthChartContainer,
      showDepthChart: config.TradingLayout.showDepthChart
    };

    if (this.props.width <= 1280) {
      return renderChartsMobile(this.props.symbol);
      //         <TradingViewWidget
      //           symbol={this.props.symbol}
      //           theme={Themes.LIGHT}
      //           range={RangeTypes.ALL}
      //           hide_legend={true}
      //           hide_side_toolbar={true}
      //           locale="en"
      //           container_id="trading-view-chart"
      //           autosize
      //         />
      //      <TradingLayoutCharts1280Px {...props} />;
    }

    return (
      <React.Fragment>
        {/*
        <div className={tradingLayoutClasses('chart-header')}>
          {this.context.t('Price Chart')}
      </div>*/}
        {renderCharts(this.props.symbol)}
        {/* 
        <TradingViewWidget
          symbol={this.props.symbol}
          //symbol={`BITSTAMP:BTCUSD`}
          locale="en"
          theme={Themes.LIGHT}
          range={RangeTypes.ALL}
          hide_legend={true}
          hide_side_toolbar={false}
          locale="en"
          container_id="trading-view-chart"
          autosize
        />
               <PriceChartContainer /> */}
        {config.TradingLayout.showDepthChart && <DepthChartContainer />}
      </React.Fragment>
    );
  };

  render() {
    return <React.Fragment>{this.renderCharts()}</React.Fragment>;
  }
}

ExchangeCharts.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ExchangeCharts;
