import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import APIcon from '../components/common/APIcon';
import redirectIfAuthorized from 'apex-web/lib/hocs/redirectIfAuthorized';
import path from '../helpers/path';
import { getBEMClasses } from '../helpers/cssClassesHelper';
import StandAloneLayout from '../layouts/StandAloneLayout/StandAloneLayout';

import SignupFormContainer from '../components/SimpleSignupForm';

// import '../styles/components/common/StandaloneForm.css';
// import './SignupPage.css';
import styled from 'styled-components';

const baseClasses = getBEMClasses('standalone-form');
const signupPageClasses = getBEMClasses('signup-page');

const Bg = styled.div`
  min-height: 120vh;
  background: transparent linear-gradient(237deg, #cc01ff 0%, #00ffdd 100%) 0%
    0% no-repeat padding-box;
`;

const LoginSection = styled.section`
  min-height: calc(100vh - 129px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
`;
export const SignupFormComponent = (props, context) => {
  return (
    <React.Fragment>
      <Bg>
        <div class="header__mobile__action d-xl-none">
          <div class="mobile__header ">
            <span class="rightsidebar btn btn-text">
              <i class="bi bi-filter-left" />
            </span>
            <span class="leftsidebar btn btn-text">
              <i class="bi bi-filter-right" />
            </span>
          </div>
        </div>
        <header>
          <div class="login__screen__btns">
            <Link to="/login" className="theme-btn withoutgradient me-0">
              Log In
            </Link>
            <Link to="/signup" className="theme-btn">
              Sign Up
            </Link>
          </div>
        </header>
        <LoginSection>
          <div className="login__screen__content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h1>Create your CryptoSX account</h1>
                </div>
                <div class="col-lg-6">
                  <div class="login__form__main">
                    <SignupFormContainer />
                    {/* <form>
                      <div class="form-group">
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Valid Email Address"
                          id="username"
                        />
                      </div>
                      <div class="form-group">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Password"
                          id="password"
                        />
                        <div id="password" class="form-text">
                          Contains at least 8 characters, 1 number, 1 capital
                          letter
                        </div>
                      </div>
                      <div class="form-group">
                        <input
                          type="password"
                          class="form-control"
                          placeholder="Retype Password"
                          id="retypepassword"
                        />
                      </div>
                      <button type="submit" class="theme-btn">
                        Sign Up
                      </button>
                    </form> */}
                    <div class="cant__login">
                      <ul>
                        <li>
                          <Link to="/login">
                            Already have an account? Sign in
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="signup__underformtext">
                    <p>
                      By Clicking Sign up you accept our Terms and Conditions
                      and Privacy Policy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LoginSection>
      </Bg>
    </React.Fragment>
  );
};

SignupFormComponent.contextTypes = {
  t: PropTypes.func.isRequired
};

export default redirectIfAuthorized(
  SignupFormComponent,
  process.env.REACT_APP_DEFAULT_PATH
);
