import { connect } from 'react-redux';
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form';
import SimpleSignupFormComponent from './SimpleSignupFormComponent';
import {
  signup,
  clearSignupError
} from 'apex-web/lib/redux/actions/signupActions';
import { preprocessErrorTextForReduxForm } from 'apex-web/lib/helpers/errorTextFormatter';
import get from 'lodash/get';

var SimpleSignupFormForm = reduxForm({
  form: 'signup',
  onSubmit: function onSubmit(payload, dispatch) {
    payload.username = payload.email;
    return dispatch(signup(payload)).then(function(response) {
      if (response && response.errormsg) {
        if (response.errormsg === 'Username already exists.') {
          response.errormsg = 'Email already exists.';
        }
        /**
         * To leverage `redux-form` field-specific error functionality,
         *  we must throw a `SubmissionError` from decorated component's `handleSubmit` prop.
         */
        throw new SubmissionError(
          preprocessErrorTextForReduxForm(response.errormsg, payload)
        );
      }
    });
  }
})(props => {
  return (
    <SimpleSignupFormComponent {...{ ...props, useEmailAsUsername: true }} />
  );
});

var selector = formValueSelector('signup');

var mapStateToProps = function mapStateToProps(state, ownProps) {
  return Object.assign(
    {
      passwordValue: selector(state, 'password'),
      errorMsg: get(state, 'form.signup.error', false),
      done: !!state.signup.userId
    },
    ownProps
  );
};

var mapDispatchToProps = {
  clearSignupError: clearSignupError
};

var SimpleSignupFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleSignupFormForm);

export default SimpleSignupFormContainer;
