import L from 'lodash';

export let validateUserRegistrationJumio = payload =>
  ((apiToken, apiSecret) =>
    fetch(
      'https://cryptosx-initiate.herokuapp.com/?url=https://netverify.com/api/v4/initiate/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'User-Agent': 'Cryptosx test/1.0.1',
          Authorization: `Basic ${btoa(`${apiToken}:${apiSecret}`)}`
        },
        body: JSON.stringify({
          customerInternalReference: L.get(payload, [
            'clientInfo',
            'userEmail'
          ]),
          userReference: L.get(payload, ['clientInfo', 'alphaPointUserID']),
          successUrl: 'https://app.cryptosx.io/jumio.html?jumiosuccess=1',
          errorUrl: 'https://app.cryptosx.io/jumio.html?jumioerror=1'
        })
      }
    )
      .then(res => res.json())
      .then(resp =>
        Object.assign(resp, {
          RedirectUrl: resp['redirectUrl']
        })
      ))(
    //.then(e => (alert(JSON.stringify(payload,null,2)), e))
    '8a054892-c005-41b8-8d61-ea6088503265',
    'kk9Cq1Px8B0HcuJJY295dWWf5a0iSm88'
  );
