import React from 'react';
import InstrumentSelectorContainer from 'apex-web/lib/components/InstrumentSelector/InstrumentSelectorContainer';
import InstrumentTableContainer from 'apex-web/lib/components/InstrumentTable/InstrumentTableContainer';
import InstrumentTickers from './InstrumentTickers';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';

import './InstrumentRowComponent.css';

var baseClasses = getBEMClasses('instrument-row');

var InstrumentRowComponent = function InstrumentRowComponent(props) {
  //  return React.createElement(
  //    'div',
  //    { className: baseClasses() },
  //    React.createElement(InstrumentSelectorContainer, null),
  //    React.createElement(InstrumentTableContainer, null)
  //  );
  return (
    <div className="instrument-row">
      <InstrumentSelectorContainer />
      <InstrumentTickers {...props} />
    </div>
  );
};

export default InstrumentRowComponent;
